import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import itStrings from './it.json';
import enStrings from './en.json';
import { it } from 'date-fns/locale';
import en from 'date-fns/locale/en-GB';
import { isBrowser } from '../utils/isBrowser';

export const dateFNSMappedLocales = {
  it: it,
  en: en
};

/**
 * Custom language detector, the one provided by i18next doens't work here
 * It's just a check on window navigator language
 *
 */
export const customLanguageDetector = () => {
  if (isBrowser()) {
    return window.navigator.language.includes('it') ? 'it' : 'en';
  }
  return 'it';
};

export const setupLocalizations = () => {
  // This function is used also in the gatsby-ssr.js file so we need to check if window is undefined
  let language = 'it';
  language = customLanguageDetector();

  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      initImmediate: false,

      resources: {
        it: {
          translation: itStrings
        },
        en: {
          translation: enStrings
        }
      },
      lng: language,
      fallbackLng: 'en',

      interpolation: {
        escapeValue: false
      }
    });
};

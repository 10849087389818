import React, { createContext, useContext } from 'react';
// CONTEXT
var DSContext = createContext({});
// PROVIDER
export var DSProvider = function (_a) {
    var children = _a.children, value = _a.value;
    if (!value.LinkComponent) {
        throw new Error('LinkComponent missing in DSProvider');
    }
    if (!value.defaultColor) {
        throw new Error('defaultColor missing in DSProvider');
    }
    return React.createElement(DSContext.Provider, { value: value }, children);
};
// HOOKS
export var useLinkComponent = function () {
    return useContext(DSContext).LinkComponent;
};
export var useDefaultColor = function () {
    return useContext(DSContext).defaultColor;
};
export var useIcons = function () {
    return useContext(DSContext).icons;
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import React from 'react';
import { useDefaultColor, useIcons } from '../DSProvider';
import { getButtonClass } from './shared';
import '../generated/styles/index.css';
export var DsButton = function (_a) {
    var children = _a.children, color = _a.color, size = _a.size, className = _a.className, onClick = _a.onClick, disabled = _a.disabled, type = _a.styleType, leadingIcon = _a.leadingIcon, trailingIcon = _a.trailingIcon, loading = _a.loading, htmlButtonProps = __rest(_a, ["children", "color", "size", "className", "onClick", "disabled", "styleType", "leadingIcon", "trailingIcon", "loading"]);
    var defaultColor = useDefaultColor();
    var Loading = useIcons().Loading;
    return (React.createElement("button", __assign({ onClick: onClick, disabled: disabled || loading, className: classNames(getButtonClass({ color: color || defaultColor, size: size, className: className, disabled: disabled, type: type }), 'relative') }, htmlButtonProps),
        loading ? (React.createElement("span", { className: "absolute inset-0 flex items-center justify-center text-white" },
            React.createElement("span", { className: "inline-block w-5 spin-slow" },
                React.createElement(Loading, null)))) : null,
        React.createElement("span", { className: classNames('inline-flex items-center flex-1', loading ? 'invisible' : null) },
            leadingIcon ? React.createElement("span", { className: "inline-block mr-3 w-5" }, leadingIcon) : null,
            React.createElement("span", { className: "flex-1" }, children),
            trailingIcon ? React.createElement("span", { className: "inline-block ml-3 w-5" }, trailingIcon) : null)));
};

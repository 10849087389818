var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import '../generated/input/input.css';
import classNames from 'classnames';
import { useIcons } from '../DSProvider';
export var Input = forwardRef(function (_a, ref) {
    var className = _a.className, status = _a.status, disabled = _a.disabled, leadingIcon = _a.leadingIcon, trailingIcon = _a.trailingIcon, props = __rest(_a, ["className", "status", "disabled", "leadingIcon", "trailingIcon"]);
    var _b = useIcons(), CircleOk = _b.CircleOk, CircleError = _b.CircleError;
    return (React.createElement("div", { className: classNames('input-container', status, className, disabled && 'disabled') },
        leadingIcon && React.createElement("span", { className: "icon leading" }, leadingIcon),
        React.createElement("input", __assign({ type: "text" }, props, { disabled: disabled, ref: ref })),
        trailingIcon && React.createElement("span", { className: "icon trailing" }, trailingIcon),
        status === 'error' && (React.createElement("span", { className: "icon trailing status" },
            React.createElement(CircleError, null))),
        status === 'validated' && (React.createElement("span", { className: "icon trailing status" },
            React.createElement(CircleOk, null)))));
});
export var InputGroup = function (_a) {
    var children = _a.children;
    return React.createElement("div", { className: "input-group" }, children);
};

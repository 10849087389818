import React from 'react';
import { DSProvider, TDSContext } from 'spedire-design-system';
import CircleOk from './src/assets/streamlinehq/streamline-bold/interface-essential/form-validation/check-circle-1.svg';
import ChevronDown from './src/assets/streamlinehq/streamline-micro-bold/interface-essential/arrows/interface-arrows-button-down.svg';
import ChevronUp from './src/assets/streamlinehq/streamline-micro-bold/interface-essential/arrows/interface-arrows-button-up.svg';
import CircleError from './src/assets/streamlinehq/streamline-mini-bold/interface-essential/alerts/interface-alert-warning-circle.svg';
import Loading from './src/assets/streamlinehq/streamline-regular/interface-essential/loading/loading.svg';
import Check from './src/assets/streamlinehq/streamline-mini-bold/interface-essential/validation/interface-validation-check.svg';
import { Link } from 'gatsby';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './src/store';
import { SpedireToastContainer } from './src/features/toasts';
import { Provider } from 'react-redux';

const LinkComponent = ({ to, ...p }) => <Link {...p} to={to} />;

// Design system context value
const dsContextValue: TDSContext = {
  LinkComponent,
  defaultColor: 'blue',
  icons: {
    ChevronDown,
    ChevronUp,
    CircleError,
    CircleOk,
    Loading,
    Check
  }
};

export const createWrapper = ({ ssr }: { ssr: boolean }) => {
  const MaybePersistProvider = ssr
    ? React.Fragment
    : ({ children }) => <PersistGate persistor={persistor}>{children}</PersistGate>;

  const Wrapper = ({ element }) => (
    <DSProvider value={dsContextValue}>
      <SpedireToastContainer />
      <MaybePersistProvider>
        <Provider store={store}>{element}</Provider>
      </MaybePersistProvider>
    </DSProvider>
  );
  return Wrapper;
};

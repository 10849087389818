import React from 'react';
import { useDefaultColor, useLinkComponent } from '../DSProvider';
import { getButtonClass } from './shared';
export var DsLinkButton = function (_a) {
    var children = _a.children, color = _a.color, size = _a.size, className = _a.className, href = _a.href, disabled = _a.disabled, leadingIcon = _a.leadingIcon, trailingIcon = _a.trailingIcon, styleType = _a.styleType;
    var Link = useLinkComponent();
    var defaultColor = useDefaultColor();
    var resultingClasses = getButtonClass({ color: color || defaultColor, size: size, className: className, type: styleType, disabled: disabled });
    var content = (React.createElement("span", { className: 'inline-flex items-center flex-1 text-center' },
        leadingIcon ? React.createElement("span", { className: "inline-block mr-3 w-5" }, leadingIcon) : null,
        React.createElement("span", { className: "flex-1" }, children),
        trailingIcon ? React.createElement("span", { className: "inline-block ml-3 w-5" }, trailingIcon) : null));
    if (href.includes('https://') || href.includes('http://')) {
        return (React.createElement("a", { href: href, className: resultingClasses }, content));
    }
    return (React.createElement(Link, { to: href, className: resultingClasses }, content));
};

import classNames from 'classnames';
var dimensionSharedClasses = 'inline-flex items-center border border-transparent font-medium shadow-sm text-white focus:ring-2 focus:outline-none focus:ring-offset-2';
var dimensionClasses = {
    xs: dimensionSharedClasses + " px-2.5 py-1.5 text-xs rounded",
    sm: dimensionSharedClasses + " px-3 py-2 text-sm leading-4 rounded-md",
    md: dimensionSharedClasses + " px-4 py-2 leading-5 text-sm rounded-md",
    lg: dimensionSharedClasses + " px-4 py-2 text-base rounded-md",
    xl: dimensionSharedClasses + " px-6 py-3 text-base rounded-md"
};
var getColors = function (color, type, disabled) {
    var colors = {
        green: {
            primary: "text-white bg-green-600 " + (!disabled ? "hover:bg-green-700 focus:ring-green-600" : ''),
            secondary: "text-green-700 bg-green-100 " + (!disabled ? "hover:bg-green-200 focus:ring-green-600" : ''),
            white: "text-gray-700 border-gray-300 bg-white " + (!disabled ? "focus:ring-green-600" : null),
            text: "border-transparent\tbg-transparent text-green-700 " + (!disabled ? "hover:text-green-900 hover:bg-green-50 focus:ring-green-600 focus:text-green-900" : null) + " shadow-none"
        },
        blue: {
            primary: "text-white bg-blue-600 " + (!disabled ? "hover:bg-blue-700 focus:ring-blue-600" : ''),
            secondary: "text-blue-700 bg-blue-100 " + (!disabled ? "hover:bg-blue-200 focus:ring-blue-600" : ''),
            white: "text-gray-700 border-gray-300 bg-white " + (!disabled ? "focus:ring-blue-600" : ''),
            text: "border-transparent\tbg-transparent text-blue-700 " + (!disabled ? "hover:text-blue-900 hover:bg-blue-50 focus:ring-blue-600 focus:text-blue-900" : '') + " shadow-none"
        },
        yellow: {
            primary: "text-white bg-yellow-600 " + (!disabled ? "hover:bg-yellow-700 focus:ring-yellow-600" : ''),
            secondary: "text-yellow-700 bg-yellow-100 " + (!disabled ? "hover:bg-yellow-200 focus:ring-yellow-600" : null),
            white: "text-gray-700 border-gray-300 bg-white " + (!disabled ? "focus:ring-yellow-600" : ''),
            text: "border-transparent\tbg-transparent text-yellow-700 " + (!disabled ? "hover:text-yellow-900 hover:bg-yellow-50 focus:ring-yellow-600 focus:text-yellow-900" : '') + " shadow-none"
        },
        red: {
            primary: "text-white bg-red-600 " + (!disabled ? "hover:bg-red-700 focus:ring-red-600" : ''),
            secondary: "text-red-700 bg-red-100 " + (!disabled ? "hover:bg-red-200 focus:ring-red-600" : ''),
            white: "text-gray-700 border-gray-300 bg-white " + (!disabled ? "focus:ring-red-600" : ''),
            text: "border-transparent\tbg-transparent text-red-700 " + (!disabled ? "hover:text-red-900 hover:bg-red-50 focus:ring-red-600 focus:text-red-900" : '') + " shadow-none"
        }
    };
    return colors[color][type];
};
export var getButtonClass = function (_a) {
    var color = _a.color, _b = _a.size, size = _b === void 0 ? 'md' : _b, className = _a.className, disabled = _a.disabled, _c = _a.type, type = _c === void 0 ? 'primary' : _c;
    var defaultPrimayColor = 'blue';
    return classNames(dimensionClasses[size], getColors(color || defaultPrimayColor, type, disabled), disabled ? 'opacity-50 cursor-not-allowed' : '', className);
};
